import {useEffect, useState} from "react";
import {getAllDepartamentos, IDepartamento} from "../api/jobOfferLocation";

/**
 * Hook para obtener el listado de departamentos
 */
export function useDepartamentos() {
    const [departamentos, setDepartamentos] = useState<IDepartamento[]>();
    const [inProgress, setInProgress] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(true);
        setHasError(false);

        getAllDepartamentos()
            .then(result => setDepartamentos(result))
            .catch((err) => setHasError(true))
            .finally(() => setInProgress(false));
    }, []);

    return {departamentos, inProgress, hasError}
}