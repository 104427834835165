import {Box, BoxProps, Icon, Input, InputGroup, InputLeftElement, Text} from "@chakra-ui/react";
import {MdSearch} from 'react-icons/md';
import {ChangeEvent, useCallback} from "react";

interface ISearchBar extends Omit<BoxProps, 'onChange'> {
    value?: string,
    onChange: (searchText: string) => void
}

export function SearchBar(props: ISearchBar) {
    const {value, onChange} = props;

    const onSearchChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        onChange(evt.target.value)
    }, [onChange]);

    return(
        <Box flexGrow={props.flexGrow} mr={props.mr}>
            <InputGroup>
                <InputLeftElement pointerEvents="none" children={<Icon as={MdSearch} />}></InputLeftElement>
                <Input bg="white" type="text" value={value} onChange={onSearchChange} placeholder="Buscar"
                       borderRadius={10} />
            </InputGroup>
        </Box>
    );
}