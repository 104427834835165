import {
    Text,
    Card,
    CardBody,
    Heading,
    CardHeader,
    Button,
    Icon,
    HStack, Flex
} from "@chakra-ui/react";
import {IJobOffer} from "../api/jobOffers";
import {Link} from "react-router-dom";
import {MdLocationOn} from 'react-icons/md';
import {FaArrowCircleRight} from 'react-icons/fa';
import {getJobOfferStringLocation} from '../utils/jobOfferDataUtils';

interface JobOfferCardProps {
    jobOffer: IJobOffer,
}

export function JobOfferCard(props: JobOfferCardProps) {
    const {jobOffer} = props;

    return (
        <Card borderRadius={20} size="sm" background="primaryComponentBg.500">
            <CardHeader>
                <Flex>
                    <Heading flex={1} textColor="brandPrimary.600" size='lg'>{jobOffer.title}</Heading>
                    <Button colorScheme="brandPrimary" as={Link} to={`/jobs/${jobOffer.idJobOffer}`}>
                        Ver oferta
                        <Icon ml={2} as={FaArrowCircleRight}></Icon>
                    </Button>
                </Flex>
                <HStack>
                    <Icon as={MdLocationOn}></Icon>
                    <Text as='b'>
                        {getJobOfferStringLocation(jobOffer)}
                    </Text>
                </HStack>
            </CardHeader>
            <CardBody>
                <Text noOfLines={2}>{jobOffer.description}</Text>
            </CardBody>
        </Card>
    )
}