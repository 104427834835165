import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {HomePage} from "./HomePage";
import * as React from "react";
import {JobOfferPage} from "./JobOfferPage";
import {CandidateFormPage} from "./CandidateFormPage";
import {SuccessSubmitPage} from "./SuccessSubmitPage";
import {ApplicationContext} from "./ApplicationContext";

const router = createBrowserRouter([
    {
        element: <ApplicationContext />,
        children: [
            {
                path: "/jobs/:idJob/apply/success",
                element: <SuccessSubmitPage />
            },
            {
                path: "/jobs/:idJob/apply",
                element: <CandidateFormPage />
            },
            {
                path: "/jobs/:idJob",
                element: <JobOfferPage />
            },
            {
                path: "/jobs",
                element: <HomePage />
            },
            {
                path: "/",
                element: <HomePage />
            }
        ]
    }
]);

export function Pages() {
    return <RouterProvider router={router} />
}