import {Button, Flex, useToast, VStack} from "@chakra-ui/react";
import {useJobOffers} from "../hooks/useJobOffers";
import {DepartamentoSelect} from "../components/controls/DepartamentoSelect";
import {ChangeEvent, useEffect, useState} from "react";
import {SearchBar} from "../components/controls/SearchBar";
import {JobOfferGrid} from "../components/JobOfferGrid";
import {Pagination} from "../components/layouts/Pagination";

export function HomePage() {
    const [idDepartamento, setIdDepartamento] = useState<number>();
    const [idMunicipio, setIdMunicipio] = useState<number>();
    const [searchInput, setSearchInput] = useState<string>();


    const {
        jobOffers,
        totalPages,
        currentPage,
        inProgress,
        hasError,
        refreshData,
        setFilters,
        setCurrentPage

    } = useJobOffers();

    useEffect(() => {
        setFilters({
            idDepartamento,
            idMunicipio,
            searchInput
        });
    }, [idDepartamento, idMunicipio, searchInput, setFilters]);

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line
    }, [currentPage]);

    const toast = useToast({
        title: 'Advertencia',
        description: 'Por favor selecciona uno o más parámetros de busqueda',
        status: 'warning',
        isClosable: true,
        duration: 5000,
        position: 'top'
    });

    function shouldSearch() {
        if (!idDepartamento && !idMunicipio && !searchInput) {
            toast();
            return;
        }

        // Regresa a la primer pagina. Este cambio activa el useEffect que ejecuta refreshData()
        if (currentPage !== 1) {
            setCurrentPage(1);
            return;
        }

        refreshData();
    }

    function handlePageChange(page: string | number) {
        if (typeof page === 'string') {
            return
        }
        setCurrentPage(page);
    }

    return (
        <VStack w="100%" mt={6}>
            <Flex bg="accentComponentBg.500" p={6} borderRadius={10} width={{base: "90%", lg: "75%"}} flexWrap={'wrap'}>
                <SearchBar flexGrow="5" mr={{base: 0, lg: 6}} value={searchInput} onChange={setSearchInput} />
                <DepartamentoSelect value={idDepartamento} flexGrow="2" mr={{base: 0, lg: 6}} mt={{base: 2, lg: 0}}
                                    width={{base: '100%', lg: "auto"}}
                                    onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                                        setIdMunicipio(undefined);
                                        setIdDepartamento(Number(evt.target.value));
                                    }} />
                <Button colorScheme="brandPrimary" borderRadius={10} onClick={() => shouldSearch()}
                        mt={{base: 2, lg: 0}} width={{base: '100%', lg: 'auto'}}>
                    Buscar
                </Button>
            </Flex>
            <JobOfferGrid jobOffers={jobOffers} hasError={hasError} inProgress={inProgress} />
            <Pagination onPageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
        </VStack>
    );
}
