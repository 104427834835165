import axios from "axios";
import {API_ENDPOINT} from "../config";
import {ServerResponseWrapper} from "./common-api-types";

export interface ICandidateData {
    name: string,
    email: string,
    identification: string,
    passport: string,
    phone: string,
    birthDate: Date,
    desiredSalary: number,
    idDepartamento: number,
    idMunicipio: number
}

export interface ICandidateResponseData extends ICandidateData {
    idCandidate: string,
    departamento: string,
    municipio: string,
    resumeTempPath: string
}

type CandidateResponse = ServerResponseWrapper<ICandidateResponseData>;

/**
 * Crea un nuevo candidato
 * @param candidateData Datos del candidato
 * @param file          Curriculum del candidato (opcional)
 */
export async function postCandidate(candidateData: ICandidateData, file: File | null = null) {
    const formData = new FormData();
    formData.append('candidate-info',
        new Blob([JSON.stringify(candidateData)], {type: 'application/json'}));

    if (file) {
        formData.append('file', new Blob([file], {type: file.type}));
    }

    const response = await axios.post<CandidateResponse>(
        `${API_ENDPOINT}/candidates`,
        formData,
        {headers: {'Content-Type': 'multipart/form-data'}}
    );

    return response.data.data;
}