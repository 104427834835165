import {Alert, AlertIcon, SimpleGrid, Spinner} from "@chakra-ui/react";
import {JobOfferCard} from "./JobOfferCard";
import {useMemo} from "react";
import {IJobOffer} from "../api/jobOffers";

interface IJobOfferGridProps {
    jobOffers?: IJobOffer[],
    hasError?: boolean,
    inProgress?: boolean
}

export function JobOfferGrid(props: IJobOfferGridProps) {
    const {jobOffers, hasError, inProgress} = props;

    const jobOfferCards = useMemo(() => {
        return jobOffers?.map(jo => <JobOfferCard key={jo.idJobOffer} jobOffer={jo} />);
    }, [jobOffers]);

    if (hasError) {
        return (
            <Alert status="error">
                <AlertIcon />
                Ocurrió un error, por favor recarga la página o vuelve a intentarlo en unos minutos
            </Alert>
        )
    }

    if (inProgress) {
        return(
            <Spinner size='xl' mt={10} />
        )
    }

    return (
        <SimpleGrid columns={{md: 1, lg: 2}} spacingY={12} spacingX={28}
                    mx={{base: 5, lg: 20}} mt={6}>
            {jobOfferCards}
        </SimpleGrid>
    )
}