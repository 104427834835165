import {Heading, HStack, Image, SimpleGrid, Text, VStack, Link, Icon, Center} from "@chakra-ui/react";
import * as React from "react";
import {ReactNode} from "react";
import logo from '../../assets/centracom-logo-light.png'
import {FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, SiTiktok} from "react-icons/all";

interface IMainLayoutProps {
    children: ReactNode
}

export function MainLayout(props: IMainLayoutProps) {
    return(
        <VStack w="100vw" h="100vh" overflow="auto">
            <HStack bg="darkAccentBg.500" w="100%">
                <Link href="/">
                    <Image src={logo} w={500} />
                </Link>
            </HStack>
            {props.children}
            <VStack bg="darkAccentBg.500" w="100%">
                <Heading size="2xl" textColor="white" mt={5}>Contáctanos</Heading>
                <SimpleGrid columns={{md: 1, lg: 3}} mb={5} spacingX={12}>
                    <Center>
                        <Link href="https://api.whatsapp.com/send/?phone=50240323432"
                              textColor="white" fontSize="xl" fontWeight="bold">
                            <Icon as={FaWhatsapp} mr={0.5} /> +502 4032 3432
                        </Link>
                    </Center>

                    <Center mt={{base: 1, lg: 0}}>
                        <Text textColor="white" fontSize="xl" fontWeight="bold">
                            referidos@centracom.com.gt
                        </Text>
                    </Center>

                    <Center mt={{base: 2, lg: 0}}>
                        <Link href="https://www.facebook.com/empleoscentracom/" target="_blank"
                              textColor="white" fontSize="xl" mr={3}>
                            <Icon as={FaFacebook} boxSize={8} />
                        </Link>
                        <Link href="https://www.linkedin.com/company/centracom-s-a/" target="_blank"
                              textColor="white" fontSize="xl"  mr={3}>
                            <Icon as={FaLinkedin} boxSize={8}  />
                        </Link>
                        <Link href="https://www.instagram.com/empleoscentracomguatemala/" target="_blank"
                              textColor="white" fontSize="xl" mr={3}>
                            <Icon as={FaInstagram} boxSize={8} />
                        </Link>
                        <Link href="https://www.tiktok.com/@centracomempleos" target="_blank"
                              textColor="white" fontSize="xl">
                            <Icon as={SiTiktok} boxSize={8} />
                        </Link>
                    </Center>
                </SimpleGrid>
            </VStack>
        </VStack>
    );
}