import {IJobOffer} from "../api/jobOffers";

/**
 * Obtiene un string con el departamento y municipio de la oferta de empleo
 * @param jobOffer
 */
export function getJobOfferStringLocation(jobOffer?: IJobOffer): string {
    if (!jobOffer) {
        return "";
    }

    return jobOffer.municipio == null ?
        jobOffer.departamento : `${jobOffer.departamento} - ${jobOffer.municipio}`
}

/**
 * Obtiene un string con la informacion del salario
 * @param jobOffer
 */
export function getJobOfferStringSalary(jobOffer?: IJobOffer): string {
    if (!jobOffer) {
        return "";
    }

    return jobOffer?.salary == null || jobOffer?.salary === 0 ?
        'Salario a definir' : `Q. ${jobOffer?.salary}`
}