import {Heading, VStack, Text, Button} from "@chakra-ui/react";
import {FaArrowCircleRight} from 'react-icons/fa';
import {Link} from "react-router-dom";

export function SuccessSubmitPage() {
    return (
        <VStack mt={5}>
            <Heading size="4xl" color="brandPrimary.500">
                ¡Éxito!
            </Heading>
            <Text fontSize="2xl" fontWeight="bold">
                Tus datos fueron registrados de forma exitosa
            </Text>
            <Button mt={2} colorScheme="brandPrimary" size="lg"
                    rightIcon={<FaArrowCircleRight />}
                    as={Link} to={`/`}>
                <Text fontSize="2xl">Ver mas empleos</Text>
            </Button>
        </VStack>
    );
}