import {useEffect, useState} from "react";
import {getJobOffer, IJobOffer} from "../api/jobOffers";

/**
 * Hook para obtener una oferta de empleo segun su id
 * @param idJobOffer
 */
export function useJobOffer(idJobOffer?: string) {
    const [jobOffer, setJobOffer] = useState<IJobOffer>();
    const [inProgress, setInProgress] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        if (!idJobOffer) {
            return;
        }
        setInProgress(true);
        setHasError(false);

        getJobOffer(idJobOffer)
            .then(result => setJobOffer(result))
            .catch((err) => {
                setHasError(true);
            })
            .finally(() => setInProgress(false));
    }, [idJobOffer]);

    return {jobOffer, inProgress, hasError};
}