import {ServerResponseWrapper} from "./common-api-types";
import axios from "axios";
import {API_ENDPOINT} from "../config";

export interface IDepartamento {
    idDepartamento: number,
    nombre: string
}

export interface IMunicipio {
    idMunicipio: number,
    nombre: string,
    idDepartamento: number
}

type DepartamentosResponse = ServerResponseWrapper<IDepartamento[]>;
type MunicipiosResponse = ServerResponseWrapper<IMunicipio[]>;

/**
 * Obtiene el listado de departamentos
 */
export async function getAllDepartamentos() {
    const response = await axios.get<DepartamentosResponse>(`${API_ENDPOINT}/location/departamentos`);
    return response.data.data;
}

/**
 * Obtiene el listado de municipios de un departamento
 * @param idDepartamento    ID del departamento
 */
export async function getMunicipiosByDepartamento(idDepartamento: number) {
    const response = await axios.get<MunicipiosResponse>(`${API_ENDPOINT}/location/municipios`, {
        params: {
            idDepartamento: idDepartamento
        }
    });
    return response.data.data;
}