import {Button, Icon, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {FaTrash, FaUpload} from "react-icons/all";
import {useRef, useState} from "react";
import {ControllerRenderProps} from "react-hook-form";

interface IFileUpload {
    onChange: ControllerRenderProps['onChange'],
    onBlur: ControllerRenderProps['onBlur'],
    name: ControllerRenderProps['name'],
    value: ControllerRenderProps['value'],
    acceptedFileTypes: string
}


export function FileUpload(props: IFileUpload) {
    const {
        onChange,
        name,
        acceptedFileTypes
    } = props;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState<string>('');

    const handleClick = () => {
        inputRef.current?.click();
    }

    return (
        <InputGroup>
            <input type='file' accept={acceptedFileTypes} hidden
                   name={name}
                   ref={inputRef}
                   onChange={(e) => {
                       setFileName(e.target.files ? e.target.files[0].name : '');
                       onChange(e.target.files ? e.target.files[0] : null);
                   }}/>
            <Input placeholder='Seleccionar archivo PDF' isReadOnly={true} value={fileName} bg='white'
                   borderRadius={10}/>
            <InputRightElement width={24}>
                <Button width={12} colorScheme="brandPrimary" borderRadius={0} onClick={handleClick}>
                    <Icon as={FaUpload}></Icon>
                </Button>
                <Button width={12} colorScheme="red" borderLeftRadius={0} borderRightRadius={10}
                        onClick={() => {
                            setFileName('');
                            onChange(null);
                        }}>
                    <Icon as={FaTrash}></Icon>
                </Button>
            </InputRightElement>
        </InputGroup>
    );
}