import {useEffect, useState} from "react";
import {getMunicipiosByDepartamento, IMunicipio} from "../api/jobOfferLocation";

/**
 * Hook para obtener el listado de departamentos de un municipio
 * @param idDepartamento
 */
export function useMunicipios(idDepartamento?: number) {
    const [municipios, setMunicipios] = useState<IMunicipio[]>();
    const [inProgress, setInprogres] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        if (!idDepartamento) {
            return;
        }

        setInprogres(true);
        setHasError(false);

        getMunicipiosByDepartamento(idDepartamento)
            .then(result => setMunicipios(result))
            .catch((err) => setHasError(true))
            .finally(() => setInprogres(false));
    }, [idDepartamento]);

    return {municipios, inProgress, hasError}
}