import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    NumberInput,
    NumberInputField,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {Controller, FieldValues, useForm} from "react-hook-form";
import {DepartamentoSelect} from "../components/controls/DepartamentoSelect";
import {useEffect, useState} from "react";
import {MunicipioSelect} from "../components/controls/MunicipioSelect";
import {FileUpload} from "../components/controls/FileUpload";
import {ICandidateData, ICandidateResponseData, postCandidate} from "../api/candidates";
import {MdSend} from 'react-icons/all'
import {applyToJobOffer} from "../api/jobOffers";
import {useNavigate, useParams} from "react-router-dom";
import {useCookies} from "react-cookie";

async function registerCandidateAndApply(candidateData: ICandidateData, file: File, idJobOffer: string,
                                                recruitmentSource: string) {

    const resultCandidate: ICandidateResponseData = await postCandidate(candidateData, file);

    await applyToJobOffer(idJobOffer, resultCandidate.idCandidate, recruitmentSource);

    return resultCandidate;
}

export function CandidateFormPage() {
    const {
        register,
        handleSubmit,
        watch,
        resetField,
        control,
        formState: {errors}
    } = useForm();

    const idDepartamento = watch("idDepartamento", undefined);

    const params = useParams();

    const navigation = useNavigate();

    useEffect(() => {
        resetField("idMunicipio");
        // eslint-disable-next-line
    }, [idDepartamento]);

    const TYPE_IDENTIFICATION = 'identification';
    const TYPE_PASSPORT = 'passport';

    const [identificationType, setIdentificationType] =
        useState<string>(TYPE_IDENTIFICATION);

    const [isSubmitingForm, setIsSubmitingForm] =
        useState<boolean>(false);

    const [cookies, setCookies, removeCookie] =
        useCookies(['recruitmentSource', 'candidateId', 'candidateName']);

    const validateFiles = (file: File) => {
        return file && file.type === 'application/pdf';
    }

    const validateIdentification = (value: string): boolean => {
        if (identificationType === TYPE_IDENTIFICATION) {
            return (value.length === 13 && /^\d+$/.test(value));
        }

        return true;
    }

    const setIdentificationValue = (value: string): string => {
        if (identificationType === TYPE_IDENTIFICATION) {
            return value.replace(/\D/g, '');
        }

        return value;
    }

    const validateEmail = (value: string): boolean => {
        const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
        return emailRegex.test(value);
    }

    const validatePhone = (value: string): boolean => {
        if (isNaN(parseInt(value))) {
            return false
        }

        if (value.toString().length !== 8) {
            return false;
        }

        return true;
    }

    const validateDesiredSalary = (value: string): boolean => {
        if (!value) {
            return true;
        }

        return !isNaN(parseFloat(value));
    }

    const toast = useToast({
        isClosable: true,
        duration: 5000,
        position: 'top'
    });

    const sendFormData = (data: FieldValues): void => {
        const candidateData: ICandidateData = {
            name: data.fullName,
            email: data.email,
            identification: identificationType === TYPE_IDENTIFICATION ? data.identification : null,
            passport: identificationType === TYPE_PASSPORT ? data.identification : null,
            phone: data.phone,
            birthDate: data.birthDate,
            desiredSalary: data.desiredSalary ?? 0,
            idDepartamento: data.idDepartamento,
            idMunicipio: data.idMunicipio
        }

        setIsSubmitingForm(true);

        registerCandidateAndApply(
            candidateData,
            data.uploadFile,
            params['idJob'] ?? '',
            cookies.recruitmentSource
        )
            .then((result) => {
                // Se remueven las cookies en caso existan
                if (cookies.candidateId) {
                    removeCookie('candidateId', {path: '/'});
                }
                if (cookies.candidateName) {
                    removeCookie('candidateName', {path: '/'});
                }

                setCookies('candidateId', result.idCandidate, {path: '/'});
                setCookies('candidateName', result.name, {path: '/'});

                navigation(`/jobs/${params['idJob']}/apply/success`);
            })
            .catch(err => {
                toast({
                    title: 'Error',
                    description: 'Ocurrió un error. Intentalo de nuevo más tarde',
                    status: 'error'
                });
                console.error(err);
            })
            .finally(() => {
                setIsSubmitingForm(false);
            });
    }

    return (
        <HStack w="100%" justifyContent="center" flex={1} p={8}>
            <Card borderRadius={20} bg='primaryComponentBg.500' w="100%" h="100%">
                <CardHeader bg="accentComponentBg.500" borderRadius={20} m={5} textAlign="center">
                    <Heading flex='1' textColor="brandPrimary.500" size='2xl'>
                        Ingresa tus datos
                    </Heading>
                </CardHeader>
                <CardBody>
                    <form noValidate id="candidate-form"
                          onSubmit={handleSubmit((data) => sendFormData(data))}>
                        <SimpleGrid columns={{md: 1, lg: 2}} spacingY={8} spacingX={12} w="100%" px={{base: 2, lg: 20}}>
                            <FormControl isRequired isInvalid={!!errors.fullName}>
                                <FormLabel>Nombre completo</FormLabel>
                                <Input
                                    {...register("fullName", {required: true})}
                                    bg='white'
                                    borderRadius={10} />
                                {errors.fullName?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isRequired isInvalid={!!errors.email}>
                                <FormLabel>Correo electrónico</FormLabel>
                                <Input
                                    {...register("email", {
                                        required: true,
                                        validate: validateEmail
                                    })}
                                    type="email"
                                    bg='white'
                                    borderRadius={10} />
                                {errors.email?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                                {errors.email?.type === 'validate' ?
                                    (<FormErrorMessage>La dirección de correo no es válida</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Tipo de identificación</FormLabel>
                                <RadioGroup {...register("identificationType")}
                                            defaultValue={TYPE_IDENTIFICATION}
                                            onChange={setIdentificationType}>
                                    <Stack direction='row'>
                                        <Radio colorScheme='brandPrimary' value={TYPE_IDENTIFICATION}>DPI</Radio>
                                        <Radio colorScheme='brandPrimary' value={TYPE_PASSPORT}>Pasaporte</Radio>
                                    </Stack>
                                </RadioGroup>
                            </FormControl>

                            <FormControl isRequired isInvalid={!!errors.identification}>
                                <FormLabel>Documento de identificación</FormLabel>
                                <Input
                                    {...register("identification", {
                                        required: true,
                                        validate: validateIdentification,
                                        setValueAs: setIdentificationValue
                                    })}
                                    bg='white'
                                    borderRadius={10}/>
                                {errors.identification?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                                {errors.identification?.type === 'validate' ?
                                    (<FormErrorMessage>
                                        El número de identificación ingresado no es válido
                                    </FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isRequired isInvalid={!!errors.phone}>
                                <FormLabel>Número de teléfono</FormLabel>
                                <NumberInput>
                                    <NumberInputField
                                        {...register("phone", {
                                            required: true,
                                            validate: value => validatePhone(value)
                                        })}
                                        type="tel"
                                        bg='white'
                                        borderRadius={10} />
                                </NumberInput>
                                {errors.phone?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                                {errors.phone?.type === 'validate' ?
                                    (<FormErrorMessage>El número de teléfono no es válido</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isRequired isInvalid={!!errors.birthDate}>
                                <FormLabel>Fecha de nacimiento</FormLabel>
                                <Input {...register("birthDate", {required: true})}
                                       type="date"
                                       bg='white'
                                       borderRadius={10} />
                                {errors.birthDate?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isRequired isInvalid={!!errors.idDepartamento}>
                                <FormLabel>¿En qué departamento vives?</FormLabel>
                                <DepartamentoSelect {...register("idDepartamento", {required: true})} />
                                {errors.idDepartamento?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isRequired isInvalid={!!errors.idMunicipio}>
                                <FormLabel>¿En qué municipio vives?</FormLabel>
                                <MunicipioSelect {...register("idMunicipio", {required: true})}
                                                 idDepartamento={Number(idDepartamento)} />
                                {errors.idMunicipio?.type === 'required' ?
                                    (<FormErrorMessage>El campo es requerido</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isInvalid={!!errors.desiredSalary}>
                                <FormLabel>Pretensión salarial (Q)</FormLabel>
                                <NumberInput>
                                    <NumberInputField
                                        {...register("desiredSalary", {
                                            validate: value => validateDesiredSalary(value),
                                            setValueAs: (value) => {
                                                if (isNaN(parseFloat(value))) {
                                                    return null;
                                                }
                                                // Al hacer submit, el valor se envia como un numero con 2 decimales
                                                return parseFloat(value).toFixed(2).toString()
                                            }
                                        })}
                                        type="text"
                                        bg='white'
                                        borderRadius={10} />
                                </NumberInput>
                                {errors.desiredSalary?.type === 'validate' ?
                                    (<FormErrorMessage>El monto ingresado no es válido</FormErrorMessage>) : ''}
                            </FormControl>

                            <FormControl isInvalid={!!errors.uploadFile}>
                                <FormLabel>Curriculum</FormLabel>
                                <Controller name="uploadFile" control={control}
                                            rules={{validate: validateFiles}}
                                            render={({field}) =>
                                                <FileUpload acceptedFileTypes={".pdf"} {...field}/>} />
                                {errors.uploadFile?.type === 'validate' ?
                                    (<FormErrorMessage>El archivo seleccionado no es válido</FormErrorMessage>) : ''}
                            </FormControl>
                        </SimpleGrid>
                        <VStack mt={5} >
                            <Button borderRadius={16} size="lg" colorScheme="brandPrimary" type="submit"
                                    width={{base: "100%", md:"75%", lg: "50%"}}
                                    rightIcon={<MdSend/>}
                                    isLoading={isSubmitingForm}
                                    loadingText="Enviando">
                                <Text fontSize="2xl">Enviar</Text>
                            </Button>
                        </VStack>
                    </form>
                </CardBody>
            </Card>
        </HStack>
    )
}