import * as React from "react"
import {ChakraProvider} from "@chakra-ui/react"
import {Pages} from "./pages";
import {theme} from "./theme"
import {MainLayout} from "./components/layouts/MainLayout";

export const App = () => (
  <ChakraProvider theme={theme}>
    <MainLayout>
            <Pages />
    </MainLayout>
  </ChakraProvider>
)
