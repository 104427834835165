import {Box, BoxProps, forwardRef, Select, SelectProps} from "@chakra-ui/react";
import {useMemo} from "react";
import {useDepartamentos} from "../../hooks/useDepartamentos";

interface IDepartamentoSelectProps extends Omit<BoxProps, 'onChange' | 'onBlur'> {
    value?: number,
    onChange: SelectProps['onChange']
    onBlur?: SelectProps['onBlur'],
    name?: SelectProps['name']
}

export const DepartamentoSelect = forwardRef(
    function RawDepartamentoSelect(props: IDepartamentoSelectProps, ref) {
        const {
            value,
            onChange,
            onBlur,
            name
        } = props;

        const {departamentos, inProgress, hasError} = useDepartamentos();

        const optionsList = useMemo(() => {
            return departamentos
                ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map(dept =>
                    <option value={dept.idDepartamento} key={dept.idDepartamento}>{dept.nombre}</option>);
        }, [departamentos]);

        if (inProgress) {
            return (
                <Box flexGrow={props.flexGrow} mr={props.mr} mt={props.mt} width={props.width}>
                    <Select ref={ref} onBlur={onBlur} name={name} bg="white" borderRadius={10} disabled>
                        <option>Cargando...</option>
                    </Select>
                </Box>
            );
        }

        if (hasError) {
            return (
                <Box flexGrow={props.flexGrow} mr={props.mr} mt={props.mt} width={props.width}>
                    <Select ref={ref} onBlur={onBlur} name={name} bg="white" borderRadius={10} disabled>
                        <option>Sin Datos</option>
                    </Select>
                </Box>
            );
        }

        return (
            <Box flexGrow={props.flexGrow} mr={props.mr} mt={props.mt} width={props.width}>
                <Select ref={ref} onBlur={onBlur} name={name} bg="white" value={value} onChange={onChange}
                        borderRadius={10}>
                    <option value=''>Departamento</option>
                    {optionsList}
                </Select>
            </Box>
        );
    });