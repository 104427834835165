import {
    Alert,
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertIcon,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    HStack,
    Icon,
    Spinner,
    Text,
    useToast
} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import {useJobOffer} from "../hooks/useJobOffer";
import {MdLocationOn, MdPersonAdd} from "react-icons/md";
import {FaMoneyBillAlt} from 'react-icons/fa';
import {getJobOfferStringLocation, getJobOfferStringSalary} from '../utils/jobOfferDataUtils';
import {useCookies} from "react-cookie";
import {useRef, useState} from "react";
import {applyToJobOffer} from "../api/jobOffers";

async function sendJobApplication(idJobOffer: string, idCandidate: string, recruitmentSource: string) {
    await applyToJobOffer(idJobOffer, idCandidate, recruitmentSource);
}

export function JobOfferPage() {
    let params = useParams();

    const {jobOffer, inProgress, hasError} = useJobOffer(params['idJob']);

    const navigation = useNavigate();

    const [cookies] =
        useCookies(['recruitmentSource', 'candidateId', 'candidateName']);

    const [showConfirmation, setShowConfirmation] =
        useState<boolean>(false);

    const cancelButtonRef = useRef<HTMLButtonElement>(null);

    const [isSendingApplication, setIsSendingApplication] =
        useState<boolean>(false);

    /**
     * Si existe una cookie con datos del candidato, entonces muestra un AlertDialog
     * Si no existe, redirecciona hacia la pagina del formulario
     */
    const handleClick = () => {
        if (!cookies.candidateId) {
            navigateToForm();
        }

        setShowConfirmation(true);
    }

    const navigateToForm = () => {
        navigation(`/jobs/${params['idJob']}/apply`);
    }

    const toast = useToast({
        isClosable: true,
        duration: 5000,
        position: 'top'
    });

    /**
     * Realiza una accion segun el boton presionado en el AlertDialog
     * Si se rechaza el mensaje, se direcciona hacia la pagina del formulario, si se acpeta entonces crea una nueva
     * aplicacion hacia una oferta de empleo
     * @param isConfirmed
     */
    const onConfirmation = (isConfirmed: boolean) => {
        if (!isConfirmed) {
            setShowConfirmation(false);
            navigateToForm();
            return;
        }

        setIsSendingApplication(true);

        sendJobApplication(params['idJob'] ?? '', cookies.candidateId, cookies.recruitmentSource)
            .then(() => {
                navigation(`/jobs/${params['idJob']}/apply/success`);
            })
            .catch(err => {
                toast({
                    title: 'Error',
                    description: 'Ocurrió un error. Intentalo de nuevo más tarde',
                    status: 'error'
                });
                console.error(err);
            })
            .finally(() => {
                setIsSendingApplication(false);
            });
    }

    if (hasError) {
        return (
            <Alert status="error">
                <AlertIcon/>
                Ocurrió un error, por favor recarga la página
            </Alert>
        );
    }

    if (inProgress) {
        return(
            <Spinner size='xl' mt={10}/>
        );
    }

    return (
        <HStack w="100%" justifyContent="center" flex={1} p={8}>

            <AlertDialog isCentered motionPreset='slideInBottom' leastDestructiveRef={cancelButtonRef}
                         isOpen={showConfirmation} onClose={() => setShowConfirmation(false)}>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Confirmar datos</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        ¿Deseas aplicar con los datos ingresados previamente, a nombre de {cookies.candidateName}?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            colorScheme='red'
                            ref={cancelButtonRef}
                            onClick={() => onConfirmation(false)} >
                            No
                        </Button>
                        <Button colorScheme='brandPrimary' ml={3} onClick={() => onConfirmation(true)}
                                isLoading={isSendingApplication} loadingText="Enviando">Si</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <Card borderRadius={20} bg='primaryComponentBg.500' w="100%" h="100%">
                <CardHeader bg="accentComponentBg.500" borderRadius={20} m={5}>
                    <Flex display={'flex'} flexWrap={'wrap'}>
                        <Heading flex='1' textColor="brandPrimary.500" size='2xl'>
                            {jobOffer?.title}
                        </Heading>
                        <Button colorScheme="brandPrimary"
                                onClick={handleClick}
                                borderRadius={16}
                                size="lg"
                                my={{base: 2, sm: 0}}
                                rightIcon={<MdPersonAdd size={24}/>}
                                width={{base: "100%", md:"20%", lg: "30%", sm: "auto"}} >
                            <Text fontSize="2xl" as='b'>Aplicar</Text>
                        </Button>
                    </Flex>
                    <HStack>
                        <Icon as={MdLocationOn} color='white' boxSize={6}></Icon>
                        <Text fontSize='2xl' textColor='white'>
                            {getJobOfferStringLocation(jobOffer)}
                        </Text>
                    </HStack>
                    <HStack>
                        <Icon as={FaMoneyBillAlt} color='white' boxSize={6}></Icon>
                        <Text fontSize='2xl' textColor='white'>
                            {getJobOfferStringSalary(jobOffer)}
                        </Text>
                    </HStack>
                </CardHeader>
                <CardBody borderBottomRadius={20} bg='primaryComponentBg.500'>
                    <Text mx={5} whiteSpace='pre-line' fontSize='xl'>{jobOffer?.description}</Text>
                </CardBody>
            </Card>
        </HStack>
    )
}