import {DOTS, usePagination} from "../../hooks/usePagination";
import {Button, ButtonGroup} from "@chakra-ui/react";
import {useEffect, useMemo, useState} from "react";

interface PaginationProps {
    onPageChange: (pageNumber: string | number) => void,
    totalPages: number,
    siblingCount?: number,
    currentPage: number
}
export function Pagination(props: PaginationProps) {
    const {onPageChange, totalPages, siblingCount = 1, currentPage} = props;
    const paginationRange = usePagination(totalPages, siblingCount, currentPage);

    const [disableLeft, setDisableLeft] = useState<boolean>(false);
    const [disableRight, setDisableRight] = useState<boolean>(false);

    const lastPage = paginationRange[paginationRange.length - 1];

    useEffect(() => {
        setDisableLeft(currentPage === 1);
        setDisableRight(currentPage === lastPage);
    }, [currentPage, lastPage]);

    const pageButtons = useMemo(() => {
        return paginationRange.map(pageNumber => {
            if (pageNumber === DOTS || typeof pageNumber === 'string') {
                return (<Button variant='ghost' isDisabled={true} colorScheme='brandPrimary'>{DOTS}</Button>);
            }

            return (
                <Button isDisabled={pageNumber === currentPage} variant='ghost' colorScheme='brandPrimary'
                        onClick={() => onPageChange(pageNumber)}>
                    {pageNumber}
                </Button>
            )
        });
    }, [onPageChange, paginationRange]);

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    function onNext() {
        onPageChange(currentPage + 1);
    }

    function onPrevious() {
        onPageChange(currentPage - 1);
    }

    return (
        <ButtonGroup size='md' isAttached mt={3}>
            <Button colorScheme='brandPrimary' isDisabled={disableLeft} onClick={onPrevious}>{'<'}</Button>
            {pageButtons}
            <Button colorScheme='brandPrimary' isDisabled={disableRight} onClick={onNext}>{'>'}</Button>
        </ButtonGroup>
    );
}