import axios from "axios";
import {API_ENDPOINT} from "../config";
import {PageDataWrapper, ServerResponseWrapper} from "./common-api-types";

export interface IJobOffer {
    idJobOffer: string,
    title: string,
    description: string,
    salary: number
    dueDate: string
    idDepartamento: number,
    departamento: string,
    idMunicipio: number,
    municipio: string
}

export interface IJobOfferFilters {
    idDepartamento?: number,
    idMunicipio?: number,
    searchInput?: string
}

export interface IJobOfferApplicationResponse {
     idJobApplication: string,
     idCandidate: string,
     idJobOffer: string,
     idRecruitmentSource: string,
     recruitmentSourceCode: string,
     recruitmentSourceName: string
}



type JobOffersResponse = ServerResponseWrapper<PageDataWrapper<IJobOffer[]>>;
type JobOfferResponse = ServerResponseWrapper<IJobOffer>;
type JobOfferApplicationResponse = ServerResponseWrapper<IJobOfferApplicationResponse>;

/**
 * Genera un nuevo objeto que incluye unicamente los campos que si tienen un valor
 * @param filters
 */
function cleanFilters(filters?: IJobOfferFilters) {
    if (typeof filters === 'undefined') {
        return {}
    }

    return Object.entries(filters).reduce(
        // @ts-ignore
        (object: IJobOfferFilters, [key, value]) => (value ? (object[key]=value, object) : object),
        {}
    );
}


/**
 * Obtiene todas las ofertas de empleo
 */
export async function getAllJobOffers(filters?: IJobOfferFilters, page?:number){
    const response = await axios.get<JobOffersResponse>(`${API_ENDPOINT}/job-offers`, {
        params: {
            ...cleanFilters(filters),
            page: page
        }
    });
    return response.data.data;
}

/**
 * Obtiene una oferta de empleo segun su id
 * @param idJobOffer    ID de la oferta de emppleo
 */
export async function getJobOffer(idJobOffer: string) {
    const response = await axios.get<JobOfferResponse>(`${API_ENDPOINT}/job-offers/${idJobOffer}`);
    return response.data.data;
}

/**
 * Registra una aplicacion hacia una oferta de empleo
 * @param idJobOffer        ID de la oferta de empleo
 * @param idCandidate       ID del candidato
 * @param recruitmentSource Codigo de la fuente de reclutamiento. Por defecto se utiliza el valor "web"
 */
export async function applyToJobOffer(idJobOffer: string, idCandidate: string, recruitmentSource: string = 'web') {
    const response = await axios.post<JobOfferApplicationResponse>(
        `${API_ENDPOINT}/job-offers/${idJobOffer}/apply`,
        {
            idCandidate: idCandidate,
            recruitmentSourceCode: recruitmentSource,
        },
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    );

    return response.data.data;
}