import {createContext, useMemo} from "react";
import {Outlet, useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";


export const ApiContext = createContext({});

export function ApplicationContext() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookies, removeCookie] = useCookies(['recruitmentSource']);

    const value = useMemo(() => {
        const sourceInQuery = searchParams.get('source');

        // Si no se incluye el query param y la cookie no existe, se coloca un valor por defecto
        if (sourceInQuery == null && !cookies.recruitmentSource) {
            setCookies('recruitmentSource', 'web', {path: '/'});
            return {};
        }

        // Si se incluye el query param y la cookie no existe, se crea una nueva
        if (sourceInQuery && !cookies.recruitmentSource) {
            setCookies('recruitmentSource', sourceInQuery, {path: '/'});
            return {};
        }

        // Si se incluye el query param y la cookie existe, se elimina y se crea una nueva
        if (sourceInQuery && cookies.recruitmentSource) {
            removeCookie('recruitmentSource', {path: '/'});
            setCookies('recruitmentSource', sourceInQuery, {path: '/'});
            return {};
        }

        return {}
        // eslint-disable-next-line
    }, [searchParams]);

    return(
        <ApiContext.Provider value={value}>
            <Outlet />
        </ApiContext.Provider>
    )
}