import {extendTheme} from "@chakra-ui/react";

export const theme = extendTheme({
    colors: {
        brandPrimary: {
            50: "#ECF5DA",
            100: "#D1ECBD",
            200: "#AEE2A1",
            300: "#86D887",
            400: "#6BCD83",
            500: "#51C185",
            600: "#45AB8A",
            700: "#3A948A",
            800: "#2F747C",
            900: "#255064"
        },
        primaryComponentBg: {
            500: "#f2f2f2"
        },
        accentComponentBg: {
            500: "#314047"
        },
        darkAccentBg: {
            500: "#1B2527"
        }
    }
});