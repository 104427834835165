import {Box, BoxProps, forwardRef, Select, SelectProps} from "@chakra-ui/react";
import {useMemo} from "react";
import {useMunicipios} from "../../hooks/useMunicipios";

interface IMunicipioSelectProps extends Omit<BoxProps, 'onChange' | 'onBlur'> {
    value?: number,
    idDepartamento?: number,
    onChange: SelectProps['onChange'],
    onBlur?: SelectProps['onBlur'],
    name?: SelectProps['name']
}

export const MunicipioSelect = forwardRef(
    function MunicipioSelect(props: IMunicipioSelectProps, ref) {
        const {
            value,
            idDepartamento,
            onChange,
            onBlur,
            name
        } = props;

        const {municipios, inProgress, hasError} = useMunicipios(idDepartamento);

        const municipiosOptions = useMemo(() => {
            return municipios
                ?.filter(m => m.idDepartamento === idDepartamento)
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map(m => <option value={m.idMunicipio} key={m.idMunicipio}>{m.nombre}</option>);
        }, [idDepartamento, municipios]);

        if (!idDepartamento) {
            return (
                <Box {...props}>
                    <Select value="" ref={ref} onBlur={onBlur} name={name} bg="white" borderRadius={10} disabled>
                        <option value="">Municipio</option>
                    </Select>
                </Box>
            );
        }

        if (inProgress) {
            return (
                <Box flexGrow={props.flexGrow} mr={props.mr} mt={props.mt} width={props.width}>
                    <Select value="" ref={ref} onBlur={onBlur} name={name} bg="white" borderRadius={10} disabled>
                        <option value="">Cargando...</option>
                    </Select>
                </Box>
            );
        }

        if (hasError) {
            return (
                <Box flexGrow={props.flexGrow} mr={props.mr} mt={props.mt} width={props.width}>
                    <Select ref={ref} onBlur={onBlur} name={name} bg="white" borderRadius={10} disabled>
                        <option>Sin Datos</option>
                    </Select>
                </Box>
            );
        }

        return (
            <Box flexGrow={props.flexGrow} mr={props.mr} mt={props.mt} width={props.width}>
                <Select ref={ref} onBlur={onBlur} name={name} bg="white" value={value} onChange={onChange}
                        borderRadius={10}>
                    <option value=''>Municipio</option>
                    {municipiosOptions}
                </Select>
            </Box>
        );
    });